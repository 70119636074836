var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height pt-4"},[_c('add-employee',{on:{"refresh":function($event){return _vm.refresh(true)}}}),_c('employee-details',{attrs:{"actions":_vm.actions,"dialog":_vm.dialogs.employee_details_dialog},on:{"refresh":_vm.refresh}}),_c('vue-loadmore',{staticStyle:{"width":"100%","direction":"ltr"},attrs:{"on-refresh":_vm.refresh_now,"finished":false,"pulling-text":_vm.$tr('Refresh'),"loosing-text":_vm.$tr('Refresh'),"refresh-text":`${_vm.$tr('Loading')}`,"loading-text":`${_vm.$tr('Loading')}`,"finished-text":_vm.$tr('Done'),"success-text":_vm.$tr('Done')}},[_c('pull-to-refresh-banner',{ref:"banner"}),_c('v-row',{staticClass:"px-0 pt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{},[_c('v-row',[_c('h1',{staticClass:"text-h3 mx-auto mb-3 font-weight-medium primary--text"},[_vm._v(" "+_vm._s(_vm.$tr("EMPLOYEE LIST"))+" ")])]),_c('v-row',{staticClass:"mb-4"},[_c('v-text-field',{attrs:{"placeholder":_vm.$tr('Search Employees'),"background-color":"grey lighten-2","light":"","solo":"","flat":"","append-icon":'mdi-magnify',"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),[_c('v-fade-transition',{attrs:{"group":""}},_vm._l((_vm.filtered_employees),function(employee,index){return _c('v-row',{key:index,staticClass:"pa-2"},[_c('card-view',{attrs:{"color":employee.pending_bundle
                                            ? 'blue lighten-3'
                                            : '#ffffff',"actions":_vm.actions,"events":[],"icon":employee.role == 'mr'
                                            ? 'mdi-hospital'
                                            : 'mdi-currency-usd',"width":"100%","title":employee.name,"text":`${employee.user_name} | ${_vm.$tr(_vm.role_map[employee.role])}`,"notification":employee.pending_bundle
                                            ? _vm.$tr('PENDING TASKS')
                                            : ''},on:{"click":function($event){return _vm.show_employee_details(employee)}}})],1)}),1)],[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-2"},[_c('v-skeleton-loader',{attrs:{"loading":true,"width":"100%","type":"article, actions"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-2"},[_c('v-skeleton-loader',{attrs:{"loading":true,"width":"100%","type":"article, actions"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-2"},[_c('v-skeleton-loader',{attrs:{"loading":true,"width":"100%","type":"article, actions"}})],1)]],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }