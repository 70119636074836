<template>
    <v-container class="fill-height pt-4">
        <add-employee @refresh="refresh(true)" />
        <employee-details
            @refresh="refresh"
            :actions="actions"
            :dialog="dialogs.employee_details_dialog"
        />

        <vue-loadmore
            :on-refresh="refresh_now"
            :finished="false"
            style="width: 100%; direction: ltr"

            :pulling-text="$tr('Refresh')"
            :loosing-text="$tr('Refresh')"
            :refresh-text="`${$tr('Loading')}`"
            :loading-text="`${$tr('Loading')}`"
            :finished-text="$tr('Done')"
            :success-text="$tr('Done')"
        >
            <pull-to-refresh-banner ref="banner"></pull-to-refresh-banner>

            <v-row class="px-0 pt-2">
                <v-col cols="12">
                    <v-container class="">
                        <v-row>
                            <h1
                                class="text-h3 mx-auto mb-3 font-weight-medium primary--text"
                            >
                                {{$tr("EMPLOYEE LIST")}}
                            </h1>
                        </v-row>
                        <v-row class="mb-4">
                            <v-text-field
                                :placeholder="$tr('Search Employees')"
                                v-model="search"
                                background-color="grey lighten-2"
                                light
                                solo
                                flat
                                :append-icon="'mdi-magnify'"
                                hide-details
                            />
                        </v-row>
                        <template>
                            <v-fade-transition group>
                                <v-row
                                    class="pa-2"
                                    v-for="(
                                        employee, index
                                    ) in filtered_employees"
                                    :key="index"
                                >
                                    <card-view
                                        :color="
                                            employee.pending_bundle
                                                ? 'blue lighten-3'
                                                : '#ffffff'
                                        "
                                        :actions="actions"
                                        @click="show_employee_details(employee)"
                                        :events="[]"
                                        :icon="
                                            employee.role == 'mr'
                                                ? 'mdi-hospital'
                                                : 'mdi-currency-usd'
                                        "
                                        width="100%"
                                        :title="employee.name"
                                        :text="`${employee.user_name} | ${$tr(role_map[employee.role])}`"
                                        :notification="
                                            employee.pending_bundle
                                                ? $tr('PENDING TASKS')
                                                : ''
                                        "
                                    />
                                </v-row>
                            </v-fade-transition>
                        </template>
                        <template>
                            <v-row v-show="loading" class="mb-2">
                                <v-skeleton-loader
                                    :loading="true"
                                    width="100%"
                                    type="article, actions"
                                ></v-skeleton-loader>
                            </v-row>
                            <v-row class="mb-2" v-show="loading">
                                <v-skeleton-loader
                                    :loading="true"
                                    width="100%"
                                    type="article, actions"
                                ></v-skeleton-loader>
                            </v-row>
                            <v-row class="mb-2" v-show="loading">
                                <v-skeleton-loader
                                    :loading="true"
                                    width="100%"
                                    type="article, actions"
                                ></v-skeleton-loader>
                            </v-row>
                        </template>
                    </v-container>
                </v-col>
            </v-row>
        </vue-loadmore>
    </v-container>
</template>

<script>
import Api from "@/api/api";
import { sync } from "vuex-pathify";

export default {
    data() {
        return {
            loading: false,
            search: "",
            dialogs: {
                employee_details_dialog: {
                    show: false,
                    employee: null,
                },
                add_employee_dialog: {
                    show: false,
                },
            },
            employees: [],
            actions: {
                loading: false,
            },
            role_map: {
                sr: "Sales-Rep",
                mr: "Marketing-Rep",
                mr_admin: "Marketing-Reps Admin",
                sr_admin: "Sales-Reps Admin",
                admin: "Admin",
            },
        };
    },
    methods: {
        show_employee_details(employee) {
            this.dialogs.employee_details_dialog.employee = employee;
            this.dialogs.employee_details_dialog.show = true;
        },
        async refresh(now = false) {
            this.loading = true;
            this.employees.splice(0);
            try {
                const response = await Api.post(
                    "employee/fetch",
                    {
                        user_id: this.user?.user_id,
                    },
                    {
                        sinceMins: 30,
                        now: now, 
                    }
                );
                this.$set(this, "employees", response.data.result.employees);
                for (const [index, employee] of this.employees.entries()) {
                    this.employees[index].search_json =
                        JSON.stringify(employee);
                }
            } catch (err) {
                console.log(err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
            this.loading = false;
        },
        async refresh_now(done) {
            await this.refresh(true);
            done();
        },
    },
    async mounted() {
        await this.refresh();
        setTimeout(async () => {
            await this.$refs?.["banner"]?.view_banner?.();
        }, 2000);
    },
    computed: {
        ...sync("user", {
            user: "user",
            loading_cover: "visualization@loading_cover",
        }),
        filtered_employees() {
            return this.employees.filter((emp) =>
                emp.search_json.includes(this.search)
            );
        },
    },
    components: {
        CardView: () =>
            import(
                /* webpackChunkName: "card_view" */
                "./components/card_view.vue"
            ),
        EmployeeDetails: () =>
            import(
                /* webpackChunkName: "employee-details" */
                "./components/employee_details.vue"
            ),
        AddEmployee: () =>
            import(
                /* webpackChunkName: "add-employee" */
                "./components/add_employee.vue"
            ),

        PullToRefreshBanner: () =>
            import("./components/pullToRefreshBanner/Index.vue"),
    },
};
</script>

<style lang="sass">
.white-pull
    & *
        color: rgba(0,0,0,0.6)
        background-color: rgba(238,238,238,0)
.full-color
    & *
</style>